<template>
  <div id="invoiceBudgetDiv" class="d-flex flex-column w-50" style="">
    <div class="vd-light-blue vd-h4 vd-padding-bottom-10">
      <strong>Budget</strong>
    </div>
    <template v-if="hasPermissionToApprove">
      <p>
        To approve the budget, enter in the disbursement value from the
        spreadsheet in the field below.
      </p>
      <div class="d-flex align-items-end">
        <form-input
          :label="'Approve disbursement value'"
          :required="true"
          :value="budgetInfo.approved_budget"
          :name="'approvedBudget'"
          :container-custom-classes="['vd-margin-right-medium']"
          :prepend-text="'$'"
          :type="'number'"
          @formInput="handleBudgetInput"
        >
        </form-input>

        <button
          class="vd-btn-medium vd-btn-blue vd-align-bottom mr-auto"
          @click="handleSaveButtonClick"
        >
          Save
        </button>

        <a
          class="vd-btn-medium vd-btn-blue vd-margin-top-small"
          target="_blank"
          :href="`${budgetInfo.spreadsheet_url}/edit`"
        >
          <i class="vd-icon icon-pencil-to-square vd-margin-right-small"></i
          >Click to open in new tab
        </a>
      </div>
    </template>

    <template v-else-if="isBudgetApproved">
      <p>
        {{ budgetInfo.approved_by.full_name }} has approved the budget on
        {{ budgetInfo.approved_at | unixToFormat('DD/MM/YY') }}
      </p>
      <div class="d-flex align-items-end">
        <form-input
          :label="'Approved disbursement value'"
          :readonly="true"
          :value="budgetInfo.approved_budget"
          :name="'approvedBudget'"
          :container-custom-classes="['vd-margin-right-medium mr-auto']"
          :prepend-text="'$'"
          :type="'number'"
        >
        </form-input>

        <a
          class="vd-btn-medium vd-btn-blue vd-margin-top-small"
          target="_blank"
          :href="`${budgetInfo.spreadsheet_url}/edit`"
        >
          <i class="vd-icon icon-pencil-to-square vd-margin-right-small"></i
          >Click to open in new tab
        </a>
      </div>
    </template>

    <template v-else>
      <p>
        Your Line Manager needs to approve your budget to proceed with
        disbursements, when your budget is ready, share this page with them.
      </p>
      <div class="d-flex align-items-end vd-margin-bottom-medium">
        <input-clipboard-copy
          class="mr-auto w-25"
          :input-value="currentUrl"
          :button-custom-class="'vd-btn-blue vd-btn-small'"
        />
        <a
          class="vd-btn-medium vd-btn-blue vd-margin-top-small"
          target="_blank"
          :href="`${budgetInfo.spreadsheet_url}/edit`"
        >
          <i class="vd-icon icon-pencil-to-square vd-margin-right-small"></i
          >Click to open in new tab
        </a>
      </div>
    </template>
    <div class="d-flex h-100">
      <iframe
        class="vd-margin-top-large vd-full-width vd-border-grey vd-border-width-1"
        :src="`${budgetInfo.spreadsheet_url}/edit?rm=minimal`"
      >
      </iframe>
    </div>
  </div>
</template>

<script>
import inputClipboardCopy from '@components/input-clipboard-copy/input-clipboard-copy'
import formInput from '@components/form/form-input'
import { mapActions, mapGetters } from 'vuex'
import { find, isEmpty } from 'lodash'
import FiltersMixin from '@mixins/filters-mixin'

export default {
  components: {
    inputClipboardCopy,
    formInput,
  },

  mixins: [FiltersMixin],

  data() {
    return {
      budgetAmount: null,
    }
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      budgetInfo: 'project/budgetInfo',
      userPermissions: 'auth/userPermissions',
    }),

    currentUrl() {
      return window.location.href
    },

    isBudgetApproved() {
      return this.budgetInfo.approved_budget !== null
    },

    hasPermissionToApprove() {
      return !isEmpty(
        find(this.userPermissions, { name: 'approve_disbursement_value' })
      )
    },
  },

  methods: {
    ...mapActions({
      updateBudget: 'project/updateBudget',
    }),

    handleBudgetInput(value) {
      this.budgetAmount = parseFloat(value)
    },

    handleSaveButtonClick() {
      this.updateBudget({
        project_id: this.project.id,
        budget: this.budgetAmount,
      })
    },
  },
}
</script>
