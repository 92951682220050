import { forEach, isEmpty, includes, isNil } from 'lodash'

/**
 * @namespace mixins/google-maps-mixin
 */

export default {
  methods: {
    getGoogleAutocomplete() {
      /* eslint-disable no-undef */
      return new google.maps.places.Autocomplete(this.$refs[this.getRefName], {
        componentRestrictions: {
          country: ['au'],
        },
        // only return the autocomplete response that we will use.
        fields: ['address_components', 'name', 'types'],
      })
    },

    initGoogleMapAutocomplete() {
      const googleAutoComplete = this.getGoogleAutocomplete()

      if (googleAutoComplete) {
        googleAutoComplete.addListener('place_changed', () => {
          let place = googleAutoComplete.getPlace()

          if (!isNil(place)) {
            const selectedPlace = this.getGoogleAddressValue(place)
            if (!isEmpty(selectedPlace.street)) {
              this.inputValue = selectedPlace
            }
          }
        })
      }
    },

    getGoogleAddressValue(val) {
      const data = {
        street: '',
        suburb: '',
        state: '',
        postcode: '',
      }

      if (includes(val.types, 'establishment') && !isEmpty(val.name)) {
        data.street += `${val.name}, `
      }

      forEach(val.address_components, (component) => {
        const addressType = component.types[0]

        switch (addressType) {
          case 'subpremise': {
            data.street += `${component.long_name}/`
            break
          }

          case 'street_number': {
            data.street += `${component.long_name} `
            break
          }

          case 'route': {
            data.street += component.short_name
            break
          }

          case 'locality': {
            data.suburb += component.long_name
            break
          }

          case 'administrative_area_level_1':
            data.state += component.short_name
            break

          case 'postal_code': {
            data.postcode += component.long_name
            break
          }

          default:
            break
        }
      })

      return data
    },
  },
}
