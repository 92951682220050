<template>
  <div
    :class="
      ['vd-field vd-position-relative', getRequiredClass].concat(
        containerCustomClasses
      )
    "
  >
    <div v-if="prependText" class="vms-input__preppend">{{ prependText }}</div>

    <label
      v-if="showLabel"
      :for="name"
      :class="labelCustomClasses"
      @click="handleLabelClickEvent"
      >{{ label }}</label
    >
    <input
      :id="key"
      :ref="getRefName"
      v-model.trim="inputValue"
      :class="[inputCustomClasses, prependText ? 'vd-padding-left-20' : '']"
      :type="type"
      :name="name"
      :required="required"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      @animationstart="handleAutofillEvent"
    />
  </div>
</template>

<script>
import GoogleMapsMixin from '@mixins/google-maps-mixin'

export default {
  mixins: [GoogleMapsMixin],

  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },

    labelCustomClasses: {
      type: String,
      required: false,
      default: '',
    },

    inputCustomClasses: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    containerCustomClasses: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },

    type: {
      type: String,
      required: true,
      default: 'text',
    },

    name: {
      type: String,
      required: true,
      default: '',
    },

    value: {
      type: [String, Number],
      required: false,
      default: '',
    },

    required: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },

    autocomplete: {
      type: String,
      required: false,
      default: 'on',
    },

    isGoogleMapsType: {
      type: Boolean,
      required: false,
      default: false,
    },

    isAlgoliaSearchType: {
      type: Boolean,
      required: false,
      default: false,
    },

    showLabel: {
      type: Boolean,
      required: false,
      default: true,
    },

    placeholder: {
      type: String,
      required: false,
      default: '',
    },

    prependText: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      inputValue: null,
      labelAsPlaceholder: true,
    }
  },

  computed: {
    key() {
      return `${this.name}Input`
    },

    getRequiredClass() {
      return this.required ? 'vd-required' : ''
    },

    getRefName() {
      return `${this.name}Input`
    },
  },

  watch: {
    inputValue(val) {
      if (this.isAlgoliaSearchType) {
        this.$emit('update:current-refinement', val)
      }

      this.$emit('formInput', val)
    },

    value(val) {
      this.inputValue = val

      if (this.inputValue !== null) {
        this.labelAsPlaceholder = false
      }

      if (this.isGoogleMapsType) {
        this.initGoogleMapAutocomplete()
      }
    },
  },

  mounted() {
    // check if has value from prop, or autofill value
    // default is null
    // this is for Chrome
    this.inputValue = this.value
      ? this.value
      : this.$refs[this.getRefName].value || null

    if (this.inputValue !== null) {
      this.labelAsPlaceholder = false
    }

    //check if input field is a google maps type
    if (this.isGoogleMapsType) {
      this.initGoogleMapAutocomplete()
    }
  },

  methods: {
    formInput(event) {
      this.$emit('formInput', event.target.value, event)
    },

    handleLabelClickEvent() {
      // focus on input
      this.$refs[this.getRefName].focus()
    },

    // Event triggered by Webkit
    handleAutofillEvent(event) {
      if (event.animationName === 'onAutoFillStart') {
        this.labelAsPlaceholder = false
      } else if (event.animationName === 'onAutoFillCancel') {
        this.labelAsPlaceholder = true
      }
    },
  },
}
</script>

<style lang="scss">
@import '@styles/components/forms/input';
</style>
