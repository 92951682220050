<template>
  <div class="vd-padding-medium" style="height: 2150px">
    <default-loader
      v-if="isInvoiceBudgetLoading"
      class="vd-margin-top-100"
    ></default-loader>

    <div
      v-else-if="!hasBudgetSpreadsheet"
      class="vms-invoices__table--empty vd-text-align-center vd-margin-top-100"
    >
      <img src="/images/empty-states/empty-price-list.svg" />
      <div class="vd-padding-top-20 vd-black">
        Clicking generate will create a spreadsheet using the budget
        template.<br />
        Pre-filling some fields based on what has been entered in the invoicing
        section.
      </div>
      <button
        id="generateSpreadsheetButton"
        class="vd-btn-medium vd-btn-blue vd-margin-top-extra-large"
        @click="handleGenerateSpreadsheetButtonClick"
      >
        Generate budget spreadsheet
      </button>
    </div>

    <template v-else>
      <project-invoices-budget-mobile v-if="isMobileScreen">
      </project-invoices-budget-mobile>

      <project-invoices-budget-desktop v-else>
      </project-invoices-budget-desktop>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { isEmpty } from 'lodash'
import DefaultLoader from '@components/loaders/default-loader'
import ProjectInvoicesBudgetDesktop from '@views/project/invoices/project-invoices-budget/project-invoices-budget-desktop'
import ProjectInvoicesBudgetMobile from '@views/project/invoices/project-invoices-budget/project-invoices-budget-mobile'

export default {
  components: {
    DefaultLoader,
    ProjectInvoicesBudgetDesktop,
    ProjectInvoicesBudgetMobile,
  },

  computed: {
    ...mapGetters({
      project: 'project/projectDetails',
      budgetInfo: 'project/budgetInfo',
      isInvoiceBudgetLoading: 'project/isInvoiceBudgetLoading',
      isMobileScreen: 'common/isMobileScreen',
    }),

    hasBudgetSpreadsheet() {
      return this.budgetInfo && !isEmpty(this.budgetInfo.spreadsheet_url)
    },
  },

  mounted() {
    this.getBudget({ project_id: this.project.id })
  },

  methods: {
    ...mapActions({
      createBudgetSpreadsheet: 'project/createBudgetSpreadsheet',
      getBudget: 'project/getBudget',
      userDetails: 'user/getUserDetails',
    }),

    handleGenerateSpreadsheetButtonClick() {
      this.createBudgetSpreadsheet({ project_id: this.project.id })
    },
  },
}
</script>
